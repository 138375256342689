exports.components = {
  "component---src-pages-404-tsx": () => import("./../../../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-templates-agency-tsx": () => import("./../../../src/templates/Agency.tsx" /* webpackChunkName: "component---src-templates-agency-tsx" */),
  "component---src-templates-contact-tsx": () => import("./../../../src/templates/Contact.tsx" /* webpackChunkName: "component---src-templates-contact-tsx" */),
  "component---src-templates-default-tsx": () => import("./../../../src/templates/Default.tsx" /* webpackChunkName: "component---src-templates-default-tsx" */),
  "component---src-templates-expertise-tsx": () => import("./../../../src/templates/Expertise.tsx" /* webpackChunkName: "component---src-templates-expertise-tsx" */),
  "component---src-templates-frontpage-tsx": () => import("./../../../src/templates/Frontpage.tsx" /* webpackChunkName: "component---src-templates-frontpage-tsx" */),
  "component---src-templates-jobs-tsx": () => import("./../../../src/templates/Jobs.tsx" /* webpackChunkName: "component---src-templates-jobs-tsx" */),
  "component---src-templates-single-job-tsx": () => import("./../../../src/templates/SingleJob.tsx" /* webpackChunkName: "component---src-templates-single-job-tsx" */),
  "component---src-templates-single-project-tsx": () => import("./../../../src/templates/SingleProject.tsx" /* webpackChunkName: "component---src-templates-single-project-tsx" */),
  "component---src-templates-work-tsx": () => import("./../../../src/templates/Work.tsx" /* webpackChunkName: "component---src-templates-work-tsx" */)
}

