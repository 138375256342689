import {
  ComponentStyleConfig,
  extendTheme,
  theme as base,
} from '@chakra-ui/react';

const colors = {
  brand: {
    violet: '#7D77FF',
    cyan: '#42ECBC',
    yellow: '#FFE212',
    pink: '#FFD3E8',
    red: '#FF8177',
  },
};

const fonts = {
  heading:
    '"Apercu Bold", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica,Arial,sans-serif',
  body: '"Miller Display Roman", Cambria, "Hoefler Text", Utopia, "Liberation Serif", "Nimbus Roman No9 L Regular", Times, "Times New Roman", serif',
};

const fontSizes = {
  xs: '12px',
  sm: '14px',
  md: '16px',
  lg: '20px',
  xl: '32px',
  '2xl': '48px',
  '3xl': '64px',
  '4xl': '80px',
};

const lineHeights = {
  none: 1,
  shorter: 1.2,
  base: 1.3,
};

const Container: ComponentStyleConfig = {
  baseStyle: {
    maxWidth: '1600px', //'1384px', // 'container.xl' = 1280
  },
};

const Button: ComponentStyleConfig = {
  variants: {
    rounded: {
      display: { base: 'flex', md: 'inline-flex' },
      height: '48px',
      paddingInlineStart: '32px',
      paddingInlineEnd: '32px',
      fontFamily: 'heading',
      fontSize: { base: '18px', md: '20px' },
      lineHeight: 'base',
      color: 'white',
      backgroundColor: 'black',
      cursor: 'pointer',
      transition: 'all 0.15s',
      transitionTimingFunction: 'cubic-bezier(0,0,.2,1)',
      borderRadius: '0px',
      _hover: {
        borderRadius: { md: '24px' },
        paddingInlineStart: { md: '40px' },
        paddingInlineEnd: { md: '40px' },
      },
      _focus: {
        boxShadow: 'none',
      },
    },
  },
};

const components = {
  Container,
  Button,
};

const styles = {
  global: {
    body: {
      color: 'black',
    },
    strong: {
      fontFamily: 'heading',
      fontWeight: 'normal',
    },
    'ul, ol': {
      marginLeft: '1em',
    },
    'ul:not(:last-child), o:not(:last-child)': {
      marginBottom: '1em',
    },
    'p:not(:last-child)': {
      marginBottom: '1em',
    },
    'blockquote:not(:last-child)': {
      marginBottom: '1em',
    },
    'blockquote p::before': {
      display: 'inline-block',
      content: '"“"',
    },
    'blockquote p::after': {
      display: 'inline-block',
      content: '"”"',
    },
    'p a': {
      textDecoration: 'underline',
    },
  },
};

export default extendTheme({
  colors,
  fonts,
  fontSizes,
  lineHeights,
  components,
  styles,
});
